// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-scale-page-scale-page-js": () => import("../src/templates/ScalePage/ScalePage.js" /* webpackChunkName: "component---src-templates-scale-page-scale-page-js" */),
  "component---src-templates-chord-page-chord-page-js": () => import("../src/templates/ChordPage/ChordPage.js" /* webpackChunkName: "component---src-templates-chord-page-chord-page-js" */),
  "component---src-templates-chord-page-all-chords-page-js": () => import("../src/templates/ChordPage/AllChordsPage.js" /* webpackChunkName: "component---src-templates-chord-page-all-chords-page-js" */),
  "component---src-templates-scale-page-all-scales-page-js": () => import("../src/templates/ScalePage/AllScalesPage.js" /* webpackChunkName: "component---src-templates-scale-page-all-scales-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-5-beautiful-color-palette-generators-for-your-next-design-project-js": () => import("../src/pages/blog/5-beautiful-color-palette-generators-for-your-next-design-project.js" /* webpackChunkName: "component---src-pages-blog-5-beautiful-color-palette-generators-for-your-next-design-project-js" */),
  "component---src-pages-blog-7-reasons-why-you-should-learn-music-theory-js": () => import("../src/pages/blog/7-reasons-why-you-should-learn-music-theory.js" /* webpackChunkName: "component---src-pages-blog-7-reasons-why-you-should-learn-music-theory-js" */),
  "component---src-pages-blog-cadences-js": () => import("../src/pages/blog/cadences.js" /* webpackChunkName: "component---src-pages-blog-cadences-js" */),
  "component---src-pages-blog-dont-give-up-on-music-theory-js": () => import("../src/pages/blog/dont-give-up-on-music-theory.js" /* webpackChunkName: "component---src-pages-blog-dont-give-up-on-music-theory-js" */),
  "component---src-pages-blog-how-to-find-new-music-js": () => import("../src/pages/blog/how-to-find-new-music.js" /* webpackChunkName: "component---src-pages-blog-how-to-find-new-music-js" */),
  "component---src-pages-blog-how-to-learn-music-theory-js": () => import("../src/pages/blog/how-to-learn-music-theory.js" /* webpackChunkName: "component---src-pages-blog-how-to-learn-music-theory-js" */),
  "component---src-pages-blog-index-js": () => import("../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-is-betapage-worth-it-js": () => import("../src/pages/blog/is-betapage-worth-it.js" /* webpackChunkName: "component---src-pages-blog-is-betapage-worth-it-js" */),
  "component---src-pages-blog-the-4-best-ear-training-apps-for-2019-js": () => import("../src/pages/blog/the-4-best-ear-training-apps-for-2019.js" /* webpackChunkName: "component---src-pages-blog-the-4-best-ear-training-apps-for-2019-js" */),
  "component---src-pages-blog-whats-new-in-solfej-v-1-3-js": () => import("../src/pages/blog/what's-new-in-solfej-v-1.3.js" /* webpackChunkName: "component---src-pages-blog-whats-new-in-solfej-v-1-3-js" */),
  "component---src-pages-chords-index-js": () => import("../src/pages/chords/index.js" /* webpackChunkName: "component---src-pages-chords-index-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-scales-index-js": () => import("../src/pages/scales/index.js" /* webpackChunkName: "component---src-pages-scales-index-js" */),
  "component---src-pages-terms-of-service-js": () => import("../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

